import "./index.css";

import * as Flex from "@twilio/flex-ui";

const attachEnvConfig = () => {
  console.log("Default Config: ", window.appConfig);
  window.appConfig = window.appConfig || {};
  let env_config = {
    serviceBaseUrl: process.env.REACT_APP_TWILIO_FLEX_RUNTIME || "",
    sso: {
      accountSid: process.env.REACT_APP_TWILIO_ACCOUNT_SID || "",
    },
    logLevel: process.env.REACT_APP_FLEX_LOGLEVEL || "info",
    language: process.env.REACT_APP_LANGUAGE || "en",
    backendServiceEndpoint: process.env.REACT_APP_SERVICE_ENDPOINT,
    backendServiceCode: process.env.REACT_APP_SERVICE_CODE,
    backendServiceBrand: process.env.REACT_APP_SERVICE_BRAND,
    backendServiceLogo: process.env.REACT_APP_SERVICE_LOGO,
    ssoLogoutRedirectUrl: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
    crmAccessTokenName: process.env.REACT_APP_API_ACCESS_TOKEN_NAME,
  };
  window.appConfig = {
    ...window.appConfig,
    ...env_config,
  };
  console.log("Attach Env Config", window.appConfig);
};

const getFlexConfig = () => {
  let predefinedConfig = window.appConfig || {};
  let configuration = {
    ...predefinedConfig,
  };

  console.log("Get Flex Config: ", configuration);
  return configuration;
};

const beforeRun = async (container) => {
  console.log("Render a loading page to ", container);
  Flex.progress(container);
  console.log("Start to attach env config");
  attachEnvConfig();
  console.log("Start to get current application config");
  let configuration = getFlexConfig();
  return Promise.resolve(configuration);
};

const run = async (container) => {
  console.log("Start to run Flex");
  beforeRun(container)
    .then((configuration) => {
      console.log("Start to init flex with config", configuration);
      return Flex.provideLoginInfo(configuration, container)
        .then(() => Flex.create(configuration))
        .then((flexHelper) => {
          flexHelper.init(container);
        })
        .catch((error) => {
          return Promise.reject(error)
        });
    })
    .catch((error) => {
      console.log(
        "Failed to run Flex. An unexpected error has occurred :",
        error
      );
      Flex.errorPage(error, container);
    });
};
const container = document.getElementById("root");
run(container);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
